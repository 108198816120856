<template>
  <b-alert
    :show="dismissCountDown"
    dismissible
    :variant="variant"
    @dismissed="dismissCountDown = 0"
    @dismiss-count-down="countDownChanged"
  >
    <p>{{ $t(messageKey) }}</p>
    <b-progress
      variant="warning"
      :max="3"
      :value="dismissCountDown"
      height="4px"
    ></b-progress>
  </b-alert>
</template>

<script>
export default {
  name: "TempoAlert",
  props: {
    variant: String,
    messageKey: String,
    parentDismissCountDown: Number
  },
  data() {
    return {
      dismissCountDown: 0
    };
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    }
  },
  watch: {
    parentDismissCountDown: function() {
      if (this.parentDismissCountDown !== 0) {
        this.dismissCountDown = this.parentDismissCountDown;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
