<template>
  <div>
    <div class="row">
      <div class="col">
        <h1 class="mt-5 m-5 text-center">{{ $t("add.time") }}</h1>
      </div>
    </div>
    <b-tabs content-class="mt-3" justified>
      <b-tab :title="$t('mode.simple')" active>
        <SimpleModeAddTimeEntry
          v-bind:userId="context.userId"
          v-bind:organisationId="context.organisationId"
          v-bind:projects="projects"
        />
      </b-tab>
      <b-tab :title="$t('mode.expert')">
        <ExpertModeAddTimeEntry
          v-bind:userId="context.userId"
          v-bind:organisationId="context.organisationId"
          v-bind:projects="projects"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import SimpleModeAddTimeEntry from "@/components/timeentry/SimpleModeAddTimeEntry";
import ExpertModeAddTimeEntry from "@/components/timeentry/ExpertModeAddTimeEntry";
import { getProjectsWithActivities } from "@/service/temposervice";
import { getActiveProjects } from "@/helper/tempohelper";

export default {
  name: "AddTimeEntry",
  components: {
    SimpleModeAddTimeEntry,
    ExpertModeAddTimeEntry
  },
  props: { context: null },
  data() {
    return {
      projects: null
    };
  },
  methods: {
    loadProjects: function() {
      getProjectsWithActivities(
        this.context.userId,
        this.context.organisationId
      ).then(response => {
        this.projects = getActiveProjects(response.data.data);
      });
    }
  },
  mounted() {
    this.loadProjects();
  }
};
</script>
