<template>
  <div class="card border-light shadow rounded text-center mb-2">
    <div class="card-body">
      <h3 class="card-title mb-2 font-weight-bold">
        {{ $t("export.timeRange") }}
      </h3>
      <b-row>
        <b-col>
          <b-form-group
            id="from"
            :label="$t('from')"
            label-for="from"
            class="mt-3"
          >
            <b-form-input type="date" v-model="begin"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group id="to" :label="$t('to')" label-for="to">
            <b-form-input type="date" v-model="end"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button v-on:click="getTimesheet" variant="light" class="mt-3">{{
        $t("export")
      }}</b-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TimeRangeTimesheetExportCard",
  data() {
    return {
      begin: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      end: moment()
        .endOf("month")
        .format("YYYY-MM-DD")
    };
  },
  methods: {
    getTimesheet: function() {
      this.$emit("getTimesheet", this.begin, this.end);
    }
  }
};
</script>
