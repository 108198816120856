<template>
  <div>
    <div class="row">
      <div class="col">
        <h1 class="mt-5 mb-5 text-center">{{ $t("export.reports") }}</h1>
      </div>
    </div>
    <b-row cols="1" cols-lg="2">
      <b-col>
        <SimpleTimesheetExportCard
          v-bind:begin="startOfCurrentMonth"
          v-bind:end="endOfCurrentMonth"
          translationKey="export.currentMonth"
          @getTimesheet="onGetTimesheet"
        />
      </b-col>
      <b-col>
        <SimpleTimesheetExportCard
          v-bind:begin="startOfPreviousMonth"
          v-bind:end="endOfPreviousMonth"
          translationKey="export.previousMonth"
          @getTimesheet="onGetTimesheet"
        />
      </b-col>
      <b-col>
        <FlexitimeExportCard @getFlexitime="onGetFlexitime" />
      </b-col>
      <b-col>
        <TimeRangeTimesheetExportCard @getTimesheet="onGetTimesheet" />
      </b-col>
      <b-col v-if="showMultipleReports">
        <TimeRangeTimesheetMultipleExportCard
          v-bind:options="projects"
          header="export.project"
          label="project.label"
          @getTimesheet="onGetMultipleProjectsTimesheet"
        />
      </b-col>
      <b-col v-if="showMultipleReports">
        <TimeRangeTimesheetMultipleExportCard
          v-bind:options="persons"
          header="export.person"
          label="employee.label"
          @getTimesheet="onGetMultiplePersonsTimesheet"
        />
      </b-col>
      <b-col v-if="showMultipleReports">
        <FlexitimeMultipleExportCard
          v-bind:options="persons"
          @getFlexitime="onGetMultiplePersonsFlexitime"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SimpleTimesheetExportCard from "@/components/export/SimpleTimesheetExportCard";
import {
  getTimesheetReport,
  getFlexitimeReport,
  getMultiplePersonsTimesheetReport,
  getMultipleProjectsTimesheetReport,
  getMultipleFlexitimeReport,
  getPersonsForOrganisation,
  getProjectsForOrganisation
} from "@/service/temposervice";
import { textOptionsCompare } from "@/helper/tempohelper";
import moment from "moment";
import TimeRangeTimesheetExportCard from "@/components/export/TimeRangeTimesheetExportCard";
import FlexitimeExportCard from "@/components/export/FlexitimeExportCard";
import TimeRangeTimesheetMultipleExportCard from "@/components/export/TimeRangeTimesheetMultipleExportCard";
import FlexitimeMultipleExportCard from "@/components/export/FlexitimeMultipleExportCard";

export default {
  name: "Export",
  components: {
    SimpleTimesheetExportCard,
    TimeRangeTimesheetExportCard,
    FlexitimeExportCard,
    TimeRangeTimesheetMultipleExportCard,
    FlexitimeMultipleExportCard
  },
  props: { context: null },
  data() {
    return {
      startOfCurrentMonth: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      endOfCurrentMonth: moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
      startOfPreviousMonth: moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD"),
      endOfPreviousMonth: moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD"),
      showMultipleReports: false,
      persons: [],
      projects: []
    };
  },
  methods: {
    loadPersons() {
      if (this.showMultipleReports) {
        getPersonsForOrganisation(this.context.organisationId).then(
          response =>
            (this.persons = this.transformPersonsResponse(response.data.data))
        );
      }
    },
    transformPersonsResponse(data) {
      let persons = [];
      data.forEach(person => {
        persons.push({
          value: person.data.id,
          text: person.data.firstName + " " + person.data.lastName
        });
      });
      return persons.sort(textOptionsCompare);
    },
    loadProjects() {
      if (this.showMultipleReports) {
        getProjectsForOrganisation(this.context.organisationId).then(
          response =>
            (this.projects = this.transformProjectsResponse(response.data.data))
        );
      }
    },
    transformProjectsResponse(data) {
      let projects = [];
      data.forEach(project => {
        projects.push({
          value: project.data.id,
          text: project.data.name
        });
      });
      return projects.sort(textOptionsCompare);
    },
    calculateShowMultipleReports() {
      if (!this.context.userRoles) {
        return false;
      }
      this.showMultipleReports =
        this.context.userRoles.includes("MANAGER") ||
        this.context.userRoles.includes("ADMIN");
    },
    onGetTimesheet(begin, end) {
      this.getTimesheet(begin, end);
    },
    getTimesheet: function(begin, end) {
      getTimesheetReport(
        this.context.userId,
        this.context.organisationId,
        begin,
        end
      ).then(response => {
        this.downloadExcelReport(response);
      });
    },
    onGetFlexitime() {
      this.getFlexitime();
    },
    getFlexitime: function() {
      getFlexitimeReport(this.context.userId, this.context.organisationId).then(
        response => {
          this.downloadExcelReport(response);
        }
      );
    },
    onGetMultiplePersonsTimesheet(begin, end, personsIds) {
      this.getMultiplePersonsTimesheet(begin, end, personsIds);
    },
    getMultiplePersonsTimesheet: function(begin, end, personsIds) {
      getMultiplePersonsTimesheetReport(
        this.context.organisationId,
        begin,
        end,
        personsIds
      ).then(response => {
        this.downloadExcelReport(response);
      });
    },
    onGetMultipleProjectsTimesheet(begin, end, projectsIds) {
      this.getMultipleProjectsTimesheet(begin, end, projectsIds);
    },
    getMultipleProjectsTimesheet: function(begin, end, projectsIds) {
      getMultipleProjectsTimesheetReport(
        this.context.organisationId,
        begin,
        end,
        projectsIds
      ).then(response => {
        this.downloadExcelReport(response);
      });
    },
    onGetMultiplePersonsFlexitime(personsIds) {
      this.getMultiplePersonsFlexitime(personsIds);
    },
    getMultiplePersonsFlexitime: function(personsIds) {
      getMultipleFlexitimeReport(this.context.organisationId, personsIds).then(
        response => {
          this.downloadExcelReport(response);
        }
      );
    },
    downloadExcelReport: function(response) {
      let headerLine = response.headers["content-disposition"];
      let startFileNameIndex = headerLine.indexOf('"') + 1;
      let endFileNameIndex = headerLine.lastIndexOf('"');
      let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

      const fileURL = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/vnd.ms-excel" })
      );
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);
      fileLink.click();
    }
  },
  mounted() {
    this.calculateShowMultipleReports();
    this.loadPersons();
    this.loadProjects();
  }
};
</script>
