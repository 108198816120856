<template>
  <div>
    <b-row>
      <b-col>
        <StatisticsCard v-bind:item="overview.total" />
      </b-col>
    </b-row>
    <b-row cols="1" cols-sm="2">
      <b-col>
        <StatisticsCard v-bind:item="overview.spent" />
      </b-col>
      <b-col>
        <StatisticsCard v-bind:item="overview.available" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getVacationOverview } from "@/service/temposervice";
import StatisticsCard from "@/components/dashboard/StatisticsCard";

export default {
  name: "VacationsOverview",
  components: {
    StatisticsCard
  },
  props: { employeeId: String, organisationId: String, year: null },
  data() {
    return {
      overview: {}
    };
  },
  methods: {
    loadOverview: function() {
      getVacationOverview(this.employeeId, this.organisationId, this.year).then(
        response => (this.overview = this.transform(response.data.data))
      );
    },
    transform: function(data) {
      return {
        total: {
          value: data.total,
          unit: "HOUR",
          key: "vacations.total"
        },
        spent: {
          value: data.spent,
          unit: "HOUR",
          key: "vacations.spent"
        },
        available: {
          value: data.available,
          unit: "HOUR",
          key: "vacations.available"
        }
      };
    }
  },
  mounted() {
    this.loadOverview();
  },
  watch: {
    employeeId: function() {
      this.loadOverview();
    },
    organisationId: function() {
      this.loadOverview();
    },
    year: function() {
      this.loadOverview();
    }
  }
};
</script>
