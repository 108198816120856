import Vue from "vue";
import i18n, { loadLanguage } from "@/i18n/i18n";
import VueRouter from "vue-router";
import EditTimeEntry from "@/components/timeentry/EditTimeEntry";
import TimeEntriesTable from "@/components/timeentry/TimeEntriesTable";
import Summary from "@/components/dashboard/Summary";
import Export from "@/components/export/Export";
import Vacations from "@/components/dashboard/report/Vacations";
import AddTimeEntry from "@/components/timeentry/AddTimeEntry";
import Invoice from "@/components/invoices/Invoice.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "App",
    component: Summary
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Summary
  },
  {
    path: "/reports/vacations",
    name: "Vacations",
    component: Vacations
  },
  {
    path: "/timeentries",
    name: "TimeEntriesTable",
    component: TimeEntriesTable
  },
  {
    path: "/timeEntry",
    name: "TimeEntry",
    component: AddTimeEntry
  },
  {
    path: "/timeEntry/edit/:timeEntryId",
    name: "EditTimeEntry",
    component: EditTimeEntry,
    props: true
  },
  {
    path: "/export",
    name: "Export",
    component: Export
  },
  {
    path: "/invoices",
    name: "Invoice",
    component: Invoice
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

const hasQueryParams = route => !!Object.keys(route.query).length;

export const hasQueryParam = (route, param) =>
  hasQueryParams(route) && Object.keys(route.query).includes(param);

router.beforeEach(async (to, from, next) => {
  if (
    !hasQueryParam(to, "lang") ||
    (hasQueryParam(to, "lang") && typeof to.query.lang !== "string")
  ) {
    to.query.lang = i18n.locale;
    next({ ...to, query: to.query });
  } else {
    await loadLanguage(to.query.lang);
    next();
  }
});

export default router;
