<template>
  <div>
    <div class="row">
      <div class="col">
        <TempoAlert
          v-bind:variant="alert.variant"
          v-bind:message-key="alert.successMessageKey"
          v-bind:parent-dismiss-count-down="alert.dismissCountDown"
        />
      </div>
    </div>
    <b-row>
      <b-col>
        <b-form @submit="onSubmit">
          <b-form-group
            id="project"
            :label="$t('project.label')"
            label-for="project"
            :description="$t('project.description')"
            class="mt-3"
          >
            <b-form-select
              id="project"
              v-model="selected"
              :options="projects"
            ></b-form-select>
          </b-form-group>

          <b-row cols="1" cols-md="2" fluid>
            <b-col>
              <b-form-group id="date" :label="$t('date')" label-for="date">
                <b-form-input type="date" v-model="date"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="timeSpent"
                :label="$t('time.spent')"
                label-for="timeSpent"
              >
                <b-input-group append="h">
                  <b-form-input
                    id="timeSpent"
                    type="number"
                    v-model="timeSpent"
                    min="0.25"
                    max="24.0"
                    step="0.25"
                    placeholder="8.5"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            id="note-group"
            :label="$t('note.label')"
            label-for="note"
            :description="$t('note.description')"
            class="mt-3"
          >
            <b-form-input
              id="note"
              v-model="note"
              type="text"
              :placeholder="$t('note.placeholder')"
              required
            ></b-form-input>
          </b-form-group>

          <b-button type="submit" variant="primary">{{
            $t("add.time")
          }}</b-button>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { createSimpleTimeEntry } from "@/service/temposervice";
import moment from "moment";
import TempoAlert from "@/components/timeentry/TempoAlert";

export default {
  name: "SimpleModeAddTimeEntry",
  components: {
    TempoAlert
  },
  props: { userId: String, organisationId: String, projects: null },
  data() {
    return {
      date: moment().format("YYYY-MM-DD"),
      selected: null,
      timeSpent: 8.5,
      note: null,
      alert: {
        variant: "success",
        successMessageKey: "timeEntry.added.success",
        dismissCountDown: 0
      }
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.addTimeEntry();
    },
    addTimeEntry: function() {
      return createSimpleTimeEntry(
        this.userId,
        this.organisationId,
        this.selected.split(".")[1],
        this.selected.split(".")[0],
        {
          date: this.date,
          timeSpent: this.timeSpent,
          comment: this.note
        }
      )
        .then(() => {
          this.triggerAlert();
        })
        .catch(() => {
          console.log("Error");
        })
        .finally(() => {
          this.resetAlertTrigger();
        });
    },
    triggerAlert: function() {
      this.alert.dismissCountDown = 3;
    },
    resetAlertTrigger: function() {
      this.alert.dismissCountDown = 0;
    }
  }
};
</script>
