<template>
  <div>
    <div class="row">
      <div class="col">
        <h1 class="mt-5 mb-5 text-center">{{ $t("vacations") }}</h1>
      </div>
    </div>
    <b-form-group
      id="year"
      :label="$t('year.label')"
      label-for="year"
      class="mt-3"
    >
      <b-form-input
        id="year"
        class="mb-5"
        type="number"
        v-model="year"
        step="1"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      v-if="showPersonsList"
      id="employee"
      :label="$t('employee.label')"
      label-for="employee"
      class="mt-3"
    >
      <b-form-select
        id="employee"
        v-model="selectedEmployee"
        :options="persons"
        class="mb-5"
      ></b-form-select>
    </b-form-group>
    <VacationsOverview
      v-bind:year="year"
      v-bind:employeeId="selectedEmployee"
      v-bind:organisationId="context.organisationId"
    />
    <VacationsEntriesTable
      class="mt-5"
      v-bind:year="year"
      v-bind:employeeId="selectedEmployee"
      v-bind:organisationId="context.organisationId"
    />
  </div>
</template>

<script>
import { getPersonsForOrganisation } from "@/service/temposervice";
import { textOptionsCompare } from "@/helper/tempohelper";
import moment from "moment";
import VacationsOverview from "@/components/dashboard/report/VacationsOverview";
import VacationsEntriesTable from "@/components/dashboard/report/VacationsEntriesTable";

export default {
  name: "Vacations",
  components: {
    VacationsOverview,
    VacationsEntriesTable
  },
  props: { context: null },
  data() {
    return {
      year: moment().year(),
      showPersonsList: false,
      persons: [],
      selectedEmployee: this.context.userId
    };
  },
  methods: {
    loadPersons() {
      if (this.showPersonsList) {
        getPersonsForOrganisation(this.context.organisationId).then(
          response =>
            (this.persons = this.transformPersonsResponse(response.data.data))
        );
      }
    },
    transformPersonsResponse(data) {
      let persons = [];
      data.forEach(person => {
        persons.push({
          value: person.data.id,
          text: person.data.firstName + " " + person.data.lastName
        });
      });
      return persons.sort(textOptionsCompare);
    },
    calculateShowPersonsList() {
      if (!this.context.userRoles) {
        return false;
      }
      this.showPersonsList =
        this.context.userRoles.includes("MANAGER") ||
        this.context.userRoles.includes("ADMIN");
    },
    resetSelectedEmployee() {
      this.selectedEmployee = this.context.userId;
    }
  },
  mounted() {
    this.calculateShowPersonsList();
    this.loadPersons();
    this.resetSelectedEmployee();
  }
};
</script>
