<template>
  <div class="card border-light shadow rounded text-center mb-2">
    <div class="card-body">
      <h3 class="card-title mb-2 font-weight-bold">
        {{ $t(translationKey) }}
      </h3>
      <b-button v-on:click="getTimesheet" variant="light">{{
        $t(translationKey)
      }}</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SimpleTimesheetExportCard",
  props: {
    translationKey: String,
    begin: String,
    end: String
  },
  methods: {
    getTimesheet: function() {
      this.$emit("getTimesheet", this.begin, this.end);
    }
  }
};
</script>
