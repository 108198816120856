import Vue from "vue";
import VueI18n from "vue-i18n";
import { getTranslations } from "@/service/temposervice";
import {
  LANGUAGES,
  DEFAULT_FALLBACK_LANGUAGE,
  SUB_LOCALE
} from "@/config/languages";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: getBrowserLanguageOrDefault(),
  fallbackLocale: DEFAULT_FALLBACK_LANGUAGE,
  messages: {},
  silentTranslationWarn: true
});

const loadedLanguages = [];

function setI18nLanguage(lang) {
  i18n.locale = lang;
  return lang;
}

function getBrowserLanguageOrDefault() {
  let browserLanguage = getBrowserLanguage();
  if (LANGUAGES.includes(browserLanguage)) {
    return browserLanguage;
  }
  return DEFAULT_FALLBACK_LANGUAGE;
}

function getBrowserLanguage() {
  return navigator.language.split("-")[0].toUpperCase();
}

export async function loadLanguage(lang) {
  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  try {
    const response = await getTranslations(lang);

    const messages = response.data.data.reduce(
      (obj, { data }) => ({
        ...obj,
        [data.id]: data.value
      }),
      {}
    );
    i18n.setLocaleMessage(lang, messages);
    loadedLanguages.push(lang);
  } catch (e) {
    i18n.setLocaleMessage(lang, {});
  }
  return setI18nLanguage(lang);
}

export function getSubLocale() {
  return SUB_LOCALE.get(i18n.locale);
}

export default i18n;
