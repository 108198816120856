<template>
  <div>
    <div class="row">
      <div class="col">
        <h1 class="mt-5 mb-5 text-center">{{ $t("invoice.title") }}</h1>
      </div>
    </div>
    <b-row cols="1">
      <b-col>
        <b-form-group :id="label" :label="$t(label)" class="mt-3">
          <b-form-select
            v-model="selectedProjects"
            :options="projects"
            :select-size="4"
            multiple
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row cols="1" cols-lg="2">
      <b-col>
        <b-form-group
          id="from"
          :label="$t('from')"
          label-for="from"
          class="mt-3"
        >
          <b-form-input type="date" v-model="begin"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group id="to" :label="$t('to')" label-for="to" class="mt-3">
          <b-form-input type="date" v-model="end"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button v-on:click="loadInvoiceData" block>{{
          $t("invoice.load.invoice")
        }}</b-button>
      </b-col>
    </b-row>

    <div v-if="invoice">
      <b-row>
        <b-col>
          <b-form-group
            id="invoiceId"
            :label="$t('invoice.id')"
            label-for="invoiceId"
            class="mt-3"
          >
            <b-form-input
              type="number"
              v-model="invoice.invoiceId"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="invoiceDate"
            :label="$t('invoice.date')"
            label-for="invoiceDate"
            class="mt-3"
          >
            <b-form-input
              type="date"
              v-model="invoice.invoiceDate"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="payableInDays"
            :label="$t('invoice.payableInDays')"
            label-for="payableInDays"
            class="mt-3"
          >
            <b-form-input
              type="number"
              v-model="invoice.payableInDays"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="sender.name"
            :label="$t('invoice.sender.name')"
            label-for="sender.name"
            class="mt-3"
          >
            <b-form-input
              type="text"
              v-model="invoice.sender.name"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="sender.street"
            :label="$t('invoice.street')"
            label-for="sender.street"
            class="mt-3"
          >
            <b-form-input
              type="text"
              v-model="invoice.sender.street"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="sender.houseNumber"
            :label="$t('invoice.houseNumber')"
            label-for="sender.houseNumber"
            class="mt-3"
          >
            <b-form-input
              type="text"
              v-model="invoice.sender.houseNumber"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="sender.postalCode"
            :label="$t('invoice.postalCode')"
            label-for="sender.postalCode"
            class="mt-3"
          >
            <b-form-input
              type="text"
              v-model="invoice.sender.postalCode"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="sender.city"
            :label="$t('invoice.city')"
            label-for="sender.city"
            class="mt-3"
          >
            <b-form-input
              type="text"
              v-model="invoice.sender.city"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="sender.email"
            :label="$t('invoice.sender.email')"
            label-for="sender.email"
            class="mt-3"
          >
            <b-form-input
              type="text"
              v-model="invoice.sender.email"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="sender.phone"
            :label="$t('invoice.sender.phone')"
            label-for="sender.phone"
            class="mt-3"
          >
            <b-form-input
              type="text"
              v-model="invoice.sender.phone"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="sender.web"
            :label="$t('invoice.sender.web')"
            label-for="sender.web"
            class="mt-3"
          >
            <b-form-input
              type="text"
              v-model="invoice.sender.web"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <b-form-group
            id="recipient.name"
            :label="$t('invoice.recipient.name')"
            label-for="recipient.name"
            class="mt-3"
          >
            <b-form-input
              type="text"
              v-model="invoice.recipient.line1"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="recipient.line2"
            :label="$t('invoice.recipient.line2')"
            label-for="recipient.line2"
            class="mt-3"
          >
            <b-form-input
              type="text"
              v-model="invoice.recipient.line2"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="recipient.street"
            :label="$t('invoice.street')"
            label-for="recipient.street"
            class="mt-3"
          >
            <b-form-input
              type="text"
              v-model="invoice.recipient.street"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="recipient.houseNumber"
            :label="$t('invoice.houseNumber')"
            label-for="recipient.houseNumber"
            class="mt-3"
          >
            <b-form-input
              type="text"
              v-model="invoice.recipient.houseNumber"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="recipient.postalCode"
            :label="$t('invoice.postalCode')"
            label-for="recipient.postalCode"
            class="mt-3"
          >
            <b-form-input
              type="text"
              v-model="invoice.recipient.postalCode"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="recipient.city"
            :label="$t('invoice.city')"
            label-for="recipient.city"
            class="mt-3"
          >
            <b-form-input
              type="text"
              v-model="invoice.recipient.city"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <b-row v-for="(l, key) in invoice.invoiceLines" :key="l.id">
        <b-col cols="3">
          <b-form-group
            id="invoice.description"
            :label="$t('invoice.description')"
            label-for="invoice.description"
          >
            <b-form-input type="text" v-model="l.description"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="invoice.amount"
            :label="$t('invoice.amount')"
            label-for="invoice.amount"
          >
            <b-form-input type="number" v-model="l.amount"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="invoice.unit"
            :label="$t('invoice.unit')"
            label-for="invoice.unit"
          >
            <b-form-input type="text" v-model="l.unit"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="invoice.middle"
            :label="$t('invoice.middle')"
            label-for="invoice.middle"
          >
            <b-form-input type="text" v-model="l.middle"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <label for="basic-url"> {{ $t("invoice.price") }}</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon3">{{
                l.price.currency
              }}</span>
            </div>
            <input
              type="number"
              class="form-control"
              id="basic-url"
              aria-describedby="basic-addon3"
              v-model="l.price.amount"
            />
          </div>
        </b-col>
        <b-col cols="2">
          <label for="basic-url"> {{ $t("invoice.subTotal") }}</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon3">{{
                l.subTotal.currency
              }}</span>
            </div>
            <input
              type="number"
              class="form-control"
              id="basic-url"
              aria-describedby="basic-addon3"
              v-model="l.subTotal.amount"
            />
          </div>
        </b-col>
        <b-col>
          <span @click="up(l, key)">Up</span> /
          <span @click="down(l, key)">Down</span></b-col
        >
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <label for="basic-url"> {{ $t("invoice.grandTotal") }}</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon3">{{
                invoice.grandTotal.currency
              }}</span>
            </div>
            <input
              type="number"
              class="form-control"
              id="basic-url"
              aria-describedby="basic-addon3"
              v-model="invoice.grandTotal.amount"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button block variant="primary" v-on:click="generateInvoice">{{
            $t("invoice.create.invoice")
          }}</b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { getProjectsForOrganisation } from "@/service/temposervice";
import { getInvoiceForOrganisationAndProject } from "@/service/temposervice";
import { generateInvoice } from "@/service/temposervice";
import { textOptionsCompare } from "@/helper/tempohelper";
import moment from "moment/moment";

export default {
  name: "Invoice",
  props: {
    context: null
  },
  data() {
    return {
      projects: [],
      selectedProjects: [],
      invoice: null,
      begin: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      end: moment()
        .endOf("month")
        .format("YYYY-MM-DD")
    };
  },
  methods: {
    loadProjects() {
      getProjectsForOrganisation(this.context.organisationId).then(
        response =>
          (this.projects = this.transformProjectsResponse(response.data.data))
      );
    },
    transformProjectsResponse(data) {
      let projects = [];
      data
        .filter(project => project.data.active === true)
        .forEach(project => {
          projects.push({
            value: project.data.id,
            text: project.data.name
          });
        });
      return projects.sort(textOptionsCompare);
    },
    loadInvoiceData() {
      getInvoiceForOrganisationAndProject(
        this.context.organisationId,
        this.selectedProjects,
        this.begin,
        this.end
      ).then(response => (this.invoice = response.data.data));
    },
    generateInvoice() {
      generateInvoice(
        this.context.organisationId,
        this.selectedProjects,
        this.begin,
        this.end,
        this.invoice
      ).then(response => {
        this.downloadOpenDocument(response);
      });
    },
    downloadOpenDocument: function(response) {
      let headerLine = response.headers["content-disposition"];
      let startFileNameIndex = headerLine.indexOf('"') + 1;
      let endFileNameIndex = headerLine.lastIndexOf('"');
      let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

      const fileURL = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.oasis.opendocument.text"
        })
      );
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    down(item, key) {
      if (key + 1 > this.invoice.invoiceLines.length) {
        console.log("Im gone " + key);
        return;
      }
      console.log("Bummer " + key);
      let itemsCopy = this.invoice.invoiceLines.slice();
      itemsCopy.splice(key, 0, itemsCopy.splice(key + 1, 1)[0]);
      this.invoice.invoiceLines = itemsCopy;
    },
    up(item, key) {
      if (key - 1 < 0) {
        return;
      }
      let itemsCopy = this.invoice.invoiceLines.slice();
      itemsCopy.splice(key, 0, itemsCopy.splice(key - 1, 1)[0]);
      this.invoice.invoiceLines = itemsCopy;
    }
  },
  mounted() {
    this.loadProjects();
  }
};
</script>
