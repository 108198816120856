<template>
  <div class="card border-light shadow rounded text-center mb-2">
    <div class="card-body">
      <h3 class="card-title mb-2 font-weight-bold">
        {{ $t("export.flexitime") }}
      </h3>
      <div>
        <b-form-group
          id="employee.label"
          :label="$t('employee.label')"
          class="mt-3"
        >
          <b-form-select
            class="mb-2"
            v-model="selected"
            :options="options"
            multiple
            :select-size="4"
          ></b-form-select>
        </b-form-group>
      </div>
      <b-button v-on:click="getFlexitime" variant="light" class="mt-3">{{
        $t("export")
      }}</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlexitimeMultipleExportCard",
  props: { options: null },
  data() {
    return {
      selected: []
    };
  },
  methods: {
    getFlexitime: function() {
      this.$emit("getFlexitime", this.selected);
    }
  }
};
</script>
