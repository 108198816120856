<template>
  <div>
    <div class="row">
      <div class="col">
        <h1 class="mt-5 mb-2 text-center">{{ $t("edit.time") }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <TempoAlert
          v-bind:variant="alert.variant"
          v-bind:message-key="alert.successMessageKey"
          v-bind:parent-dismiss-count-down="alert.dismissCountDown"
        />
      </div>
    </div>
    <ExpertForm
      v-bind:initData="timeEntry"
      v-bind:projectOptions="projects"
      action-message-key="edit.time"
      @onSubmit="onSubmit"
    />
  </div>
</template>

<script>
import {
  getProjectsWithActivities,
  getTimeEntry,
  updateTimeEntry
} from "@/service/temposervice";
import { getAllProjects } from "@/helper/tempohelper";
import TempoAlert from "@/components/timeentry/TempoAlert";
import ExpertForm from "@/components/timeentry/ExpertForm";

export default {
  name: "EditTimeEntry",
  components: { TempoAlert, ExpertForm },
  props: {
    timeEntryId: String,
    context: null
  },
  data() {
    return {
      timeEntry: {},
      projects: [],
      alert: {
        variant: "success",
        successMessageKey: "timeEntry.edited.success",
        dismissCountDown: 0
      }
    };
  },
  methods: {
    loadTimeEntry: function() {
      getTimeEntry(
        this.context.userId,
        this.context.organisationId,
        this.timeEntryId
      ).then(response => {
        this.timeEntry = response.data.data;
      });
    },
    loadProjects: function() {
      getProjectsWithActivities(
        this.context.userId,
        this.context.organisationId
      ).then(response => {
        this.projects = getAllProjects(response.data.data);
      });
    },
    onSubmit(data, selectedProject) {
      this.editTimeEntry(data, selectedProject);
    },
    editTimeEntry: function(data, selectedProject) {
      return updateTimeEntry(
        this.timeEntryId,
        this.context.userId,
        this.context.organisationId,
        selectedProject.split(".")[1],
        selectedProject.split(".")[0],
        data
      )
        .then(() => {
          this.triggerAlert();
        })
        .catch(() => {
          console.log("Error");
        })
        .finally(() => {
          this.resetAlertTrigger();
        });
    },
    triggerAlert: function() {
      this.alert.dismissCountDown = 3;
    },
    resetAlertTrigger: function() {
      this.alert.dismissCountDown = 0;
    }
  },
  mounted() {
    this.loadTimeEntry();
    this.loadProjects();
  }
};
</script>

<style scoped lang="scss"></style>
