import i18n from "@/i18n/i18n";

export function getActiveProjects(data) {
  return transformProjects(
    data.filter(project => project.data.active === true)
  );
}

export function getAllProjects(data) {
  return transformProjects(data);
}

function transformProjects(data) {
  let projectsWithActivities = [];
  data.forEach(project => {
    project.expands
      .filter(expand => expand.name === "activity")
      .flatMap(e => e.data)
      .forEach(act => {
        projectsWithActivities.push({
          value: act.id + "." + project.data.id,
          text: project.data.name + " - " + i18n.t(act.key, [])
        });
      });
  });
  return projectsWithActivities.sort(textOptionsCompare);
}

export function textOptionsCompare(option1, option2) {
  let text1 = option1.text.toLowerCase();
  let text2 = option2.text.toLowerCase();

  if (text1 < text2) {
    return -1;
  }
  if (text1 > text2) {
    return 1;
  }
  return 0;
}
