<template>
  <div class="card border-light shadow rounded text-center mb-2">
    <div class="card-body">
      <h1 class="mt-2 mb-5">{{ $t("further.reports.header") }}</h1>
      <b-row>
        <b-col>
          <router-link :to="{ name: 'Vacations' }">
            <b-button variant="light">{{ $t("vacations") }}</b-button>
          </router-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdditionalReportsCard"
};
</script>
