<template>
  <div>
    <div class="row">
      <div class="col">
        <b-table striped hover :items="vacationEntries" :fields="fields">
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getVacationEntries } from "@/service/temposervice";
import moment from "moment";
import { getSubLocale } from "@/i18n/i18n";

export default {
  name: "VacationsEntriesTable",
  props: { employeeId: String, organisationId: String, year: null },
  data() {
    return {
      fields: [
        { key: "date", label: this.$t("date") },
        { key: "amount", label: this.$t("amount") },
        { key: "note", label: this.$t("note.label") }
      ],
      vacationEntries: []
    };
  },
  methods: {
    loadVacationEntries: function() {
      getVacationEntries(this.employeeId, this.organisationId, this.year).then(
        response => (this.vacationEntries = this.transform(response.data.data))
      );
    },
    transform: function(data) {
      let entries = [];
      data.forEach(entry => {
        entries.push({
          id: entry.data.id,
          date: this.formatLocalDate(entry.data.date),
          amount: entry.data.amount,
          note: entry.data.comment
        });
      });
      return entries;
    },
    formatLocalDate: function(date) {
      let subLocale = getSubLocale();
      moment.locale(subLocale);
      return moment(String(date)).format("L");
    }
  },
  mounted() {
    this.loadVacationEntries();
  },
  watch: {
    employeeId: function() {
      this.loadVacationEntries();
    },
    organisationId: function() {
      this.loadVacationEntries();
    },
    year: function() {
      this.loadVacationEntries();
    }
  }
};
</script>
