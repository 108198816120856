<template>
  <div>
    <b-row>
      <b-col>
        <b-form @submit="onSubmit">
          <b-form-group
            id="project"
            :label="$t('project.label')"
            label-for="project"
            :description="$t('project.description')"
            class="mt-3"
          >
            <b-form-select
              id="project"
              v-model="selected"
              :options="projectOptions"
            ></b-form-select>
          </b-form-group>

          <b-row class="text-center" align-v="center">
            <b-col cols="12" lg="9">
              <b-form-group
                id="from"
                :label="$t('from')"
                label-for="from"
                class="mt-3"
                label-align="left"
              >
                <b-row cols="1" cols-md="2">
                  <b-col class="pr-3 pr-md-0">
                    <b-form-input
                      type="date"
                      v-model="startDate"
                      @change="applyToEndDate"
                    ></b-form-input>
                  </b-col>
                  <b-col class="pl-3 pl-md-0">
                    <b-form-input
                      type="time"
                      v-model="startTime"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>

              <b-form-group
                id="to"
                :label="$t('to')"
                label-for="to"
                class="mt-3"
                label-align="left"
              >
                <b-row cols="1" cols-md="2">
                  <b-col class="pr-3 pr-md-0">
                    <b-form-input type="date" v-model="endDate"></b-form-input>
                  </b-col>
                  <b-col class="pl-3 pl-md-0">
                    <b-form-input type="time" v-model="endTime"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-input-group
                class="mt-3"
                :prepend="$t('time.spent')"
                :append="$t('hours.short')"
              >
                <b-form-input
                  type="number"
                  v-model="timeSpent"
                  min="0.01"
                  step="0.01"
                  readonly
                  max="24.0"
                  placeholder="0"
                ></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>

          <b-form-group
            id="note-group"
            :label="$t('note.label')"
            label-for="note"
            :description="$t('note.description')"
            class="mt-3"
          >
            <b-form-input
              id="note"
              v-model="note"
              type="text"
              :placeholder="$t('note.placeholder')"
              required
            ></b-form-input>
          </b-form-group>

          <b-button type="submit" variant="primary">{{
            $t(actionMessageKey)
          }}</b-button>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "ExpertForm",
  props: {
    projectOptions: null,
    initData: null,
    actionMessageKey: String
  },
  data() {
    return {
      startDate: moment().format("YYYY-MM-DD"),
      startTime: "09:00",
      endDate: moment().format("YYYY-MM-DD"),
      endTime: "17:30",
      timeSpent: 8.5,
      note: null,
      initialProject: null,
      selected: null
    };
  },
  methods: {
    setData: function(data) {
      this.timeEntry = data;
      this.initialProject = {
        projectId: this.timeEntry.projectId,
        activityId: this.timeEntry.activityId
      };
      this.startDate = moment(this.timeEntry.begin).format("YYYY-MM-DD");
      this.startTime = moment(this.timeEntry.begin).format("HH:mm");
      this.endDate = moment(this.timeEntry.end).format("YYYY-MM-DD");
      this.endTime = moment(this.timeEntry.end).format("HH:mm");
      this.calculateTimeSpent();
      this.note = this.timeEntry.comment;
    },
    setInitSelectedProject: function() {
      if (
        this.initialProject &&
        this.projectOptions &&
        this.projectOptions.length
      ) {
        this.selected = this.projectOptions.find(
          it =>
            it.value ===
            this.initialProject.activityId + "." + this.initialProject.projectId
        ).value;
      }
    },
    onSubmit(evt) {
      evt.preventDefault();
      let data = this.getData();
      this.$emit("onSubmit", data, this.selected);
    },
    getData: function() {
      let beginMoment = this.getDateTime(this.startDate, this.startTime);
      let endMoment = this.getDateTime(this.endDate, this.endTime);

      return {
        begin: beginMoment.format("YYYY-MM-DDTHH:mm:SSZ"),
        end: endMoment.format("YYYY-MM-DDTHH:mm:SSZ"),
        comment: this.note
      };
    },
    calculateTimeSpent: function() {
      let beginMoment = this.getDateTime(this.startDate, this.startTime);
      let endMoment = this.getDateTime(this.endDate, this.endTime);
      let value = endMoment.diff(beginMoment);
      this.timeSpent =
        Math.round((value / 3600000 + Number.EPSILON) * 100) / 100;
    },
    getDateTime: function(date, time) {
      let dateTimeMoment = new moment(String(date));
      let timeMoment = new moment(String(time), "HH:mm");
      dateTimeMoment.hours(timeMoment.hours());
      dateTimeMoment.minutes(timeMoment.minutes());
      return dateTimeMoment;
    },
    applyToEndDate: function(someDate) {
      this.endDate = someDate;
    }
  },
  watch: {
    projectOptions: function() {
      this.setInitSelectedProject();
    },
    initData: function() {
      if (this.initData) {
        this.setData(this.initData);
        this.setInitSelectedProject();
      }
    },
    startDate: function() {
      this.calculateTimeSpent();
    },
    endDate: function() {
      this.calculateTimeSpent();
    },
    startTime: function() {
      this.calculateTimeSpent();
    },
    endTime: function() {
      this.calculateTimeSpent();
    }
  }
};
</script>

<style scoped lang="scss"></style>
