<template>
  <div>
    <div class="row">
      <div class="col">
        <h1 class="mt-5 m-5 text-center">{{ $t("tracking.overview") }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-table
          striped
          hover
          :items="timeEntries"
          :fields="fields"
          selectable
          :select-mode="mode"
          @row-selected="onRowSelected"
        >
          <template #cell(delete)="row">
            <b-button
              size="sm"
              @click="showDeleteDialog(row.item)"
              pill
              variant="outline-dark"
            >
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>
        <b-modal
          v-model="showDeleteConfirmationDialog"
          :title="$t('timeEntry.delete.header')"
          header-bg-variant="secondary"
          header-text-variant="light"
        >
          <p class="my-4">{{ $t("timeEntry.delete.body") }}</p>

          <template #modal-footer>
            <div class="w-100">
              <b-button
                variant="danger"
                size="sm"
                class="float-right m-2"
                @click="deleteEntry()"
              >
                {{ $t("delete") }}
              </b-button>

              <b-button
                variant="secondary"
                size="sm"
                class="float-right m-2"
                @click="showDeleteConfirmationDialog = false"
              >
                {{ $t("cancel") }}
              </b-button>
            </div>
          </template>
        </b-modal>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          pills
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getTimeEntries, deleteTimeEntry } from "@/service/temposervice";
import { getSubLocale } from "@/i18n/i18n";
import router from "@/router";
import moment from "moment";

export default {
  name: "TimeEntriesTable",
  props: { context: null },
  data() {
    return {
      mode: "single",
      fields: [
        { key: "date", label: this.$t("date") },
        { key: "time", label: this.$t("from") + " - " + this.$t("to") },
        { key: "duration", label: this.$t("time.spent") },
        { key: "project", label: this.$t("project.label") },
        { key: "comment", label: this.$t("note.label") },
        { key: "delete", label: "" }
      ],
      timeEntries: [],
      showDeleteConfirmationDialog: false,
      entryToDelete: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10
    };
  },
  methods: {
    loadTimeEntries: function() {
      getTimeEntries(
        this.context.userId,
        this.context.organisationId,
        this.currentPage - 1,
        this.perPage
      ).then(response => {
        this.timeEntries = this.transform(response.data.data);
        this.totalRows = response.data.pagination.totalSize;
      });
    },
    transform: function(data) {
      let entries = [];
      data.forEach(group => {
        group.data.timeEntries.forEach(entry => {
          entries.push({
            id: entry.id,
            date: this.formatLocalDate(group.data.date),
            time:
              this.formatLocalTime(entry.begin) +
              " - " +
              this.formatLocalTime(entry.end),
            duration:
              this.calculateDuration(entry.begin, entry.end) +
              " " +
              this.$t("hours.short"),
            project: entry.project + " - " + entry.activity,
            comment: entry.comment
          });
        });
      });
      return entries;
    },
    formatLocalDate: function(date) {
      let subLocale = getSubLocale();
      moment.locale(subLocale);
      return moment(String(date)).format("L");
    },
    formatLocalTime: function(date) {
      let subLocale = getSubLocale();
      moment.locale(subLocale);
      return moment(String(date)).format("LT");
    },
    calculateDuration: function(begin, end) {
      let endMoment = new moment(String(end));
      let beginMoment = new moment(String(begin));
      let value = endMoment.diff(beginMoment);
      return Math.round((value / 3600000 + Number.EPSILON) * 100) / 100;
    },
    onRowSelected(entries) {
      router.push(
        {
          name: "EditTimeEntry",
          params: {
            timeEntryId: entries[0].id,
            context: this.context
          }
        },
        () => {}
      );
    },
    showDeleteDialog(entry) {
      this.showDeleteConfirmationDialog = true;
      this.entryToDelete = entry.id;
    },
    deleteEntry() {
      this.showDeleteConfirmationDialog = false;
      deleteTimeEntry(
        this.context.userId,
        this.context.organisationId,
        this.entryToDelete
      ).then(() => {
        this.loadTimeEntries();
      });
    }
  },
  mounted() {
    this.loadTimeEntries();
  },
  watch: {
    currentPage: function() {
      this.loadTimeEntries();
    }
  }
};
</script>

<style scoped></style>
