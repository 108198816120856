<template>
  <div class="card border-light shadow rounded text-center mb-2">
    <div class="card-body">
      <h3 class="card-title mb-2 font-weight-bold">
        {{ $t(header) }}
      </h3>
      <div>
        <b-form-group :id="label" :label="$t(label)" class="mt-3">
          <b-form-select
            v-model="selected"
            :options="options"
            multiple
            :select-size="4"
          ></b-form-select>
        </b-form-group>
      </div>
      <b-row>
        <b-col>
          <b-form-group
            id="from"
            :label="$t('from')"
            label-for="from"
            class="mt-3"
          >
            <b-form-input type="date" v-model="begin"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group id="to" :label="$t('to')" label-for="to">
            <b-form-input type="date" v-model="end"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button v-on:click="getTimesheet" variant="light" class="mt-3">{{
        $t("export")
      }}</b-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TimeRangeTimesheetMultipleExportCard",
  props: { header: String, label: String, options: null },
  data() {
    return {
      begin: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      end: moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
      selected: []
    };
  },
  methods: {
    getTimesheet: function() {
      this.$emit("getTimesheet", this.begin, this.end, this.selected);
    }
  }
};
</script>
