<template>
  <div id="app">
    <b-container>
      <MenuHeader v-bind:context="user" v-if="ready" />
      <router-view v-bind:context="user" />
      <Footer v-bind:context="user" />
    </b-container>
  </div>
</template>

<script>
import MenuHeader from "./components/MenuHeader.vue";
import Footer from "./components/Footer.vue";
import { logInUser } from "@/service/temposervice";

export default {
  name: "App",
  components: {
    MenuHeader,
    Footer
  },
  data: function() {
    return {
      user: {},
      ready: false
    };
  },
  methods: {
    transform(data) {
      return {
        userId: data.id,
        username: data.username,
        userRoles: data.roles,
        organisationId: data.organisationId
      };
    }
  },
  beforeCreate: function() {
    logInUser().then(response => {
      this.user = this.transform(response.data.data);
      this.ready = true;
    });
  }
};
</script>

<style>
#app {
  font-family: "Overpass", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;*/
}
</style>
