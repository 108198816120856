<template>
  <div class="row">
    <div class="col font-weight-light text-center mb-2 mt-2">
      {{ $t("hello") }} {{ context.username }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: { context: null }
};
</script>
