import axios from "axios";
import i18n from "@/i18n/i18n";

const LOG_IN_PATH = "/oauth/login/azuread";
const LOG_OUT_PATH = "/oauth/logout/azuread";
const API_PATH = "/api/v1";

export function logInUser() {
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response.status === 401) {
        window.location.assign(LOG_IN_PATH);
      } else {
        return Promise.reject(error);
      }
    }
  );

  return axios.get(`${API_PATH}/user`);
}

export function logOutUser() {
  window.location.assign(LOG_OUT_PATH);
}

export function getTranslations(language) {
  return axios.get(`${API_PATH}/messages`, {
    headers: { "Accept-Language": language }
  });
}

export function getTimeEntries(userId, organisationId, page, limit) {
  return axios.get(
    `${API_PATH}/organisations/${organisationId}/persons/${userId}/timeentries/daily?page=${page}&limit=${limit}`,
    {
      headers: { "Accept-Language": i18n.locale }
    }
  );
}

export function getTimeEntry(userId, organisationId, timeEntryId) {
  return axios.get(
    `${API_PATH}/organisations/${organisationId}/persons/${userId}/timeentries/${timeEntryId}`
  );
}

export function deleteTimeEntry(userId, organisationId, timeEntryId) {
  return axios.delete(
    `${API_PATH}/organisations/${organisationId}/persons/${userId}/timeentries/${timeEntryId}`
  );
}

export function getStatistics(userId, organisationId) {
  return axios.get(
    `${API_PATH}/organisations/${organisationId}/persons/${userId}/statistics`
  );
}

export function getPersonsForOrganisation(organisationId) {
  return axios.get(`${API_PATH}/organisations/${organisationId}/persons`);
}

export function getVacationOverview(userId, organisationId, year) {
  return axios.get(
    `${API_PATH}/organisations/${organisationId}/persons/${userId}/vacations/${year}`
  );
}

export function getVacationEntries(userId, organisationId, year) {
  return axios.get(
    `${API_PATH}/organisations/${organisationId}/persons/${userId}/vacations/${year}/records`
  );
}

export function getProjectsWithActivities(userId, organisationId) {
  return axios.get(
    `${API_PATH}/organisations/${organisationId}/persons/${userId}/projects?expands=activity`
  );
}

export function createSimpleTimeEntry(
  userId,
  organisationId,
  projectId,
  activityId,
  requestBody
) {
  return axios.post(
    `${API_PATH}/organisations/${organisationId}/persons/${userId}/projects/${projectId}/activities/${activityId}/timeentries/simple`,
    requestBody
  );
}

export function createExpertTimeEntry(
  userId,
  organisationId,
  projectId,
  activityId,
  requestBody
) {
  return axios.post(
    `${API_PATH}/organisations/${organisationId}/persons/${userId}/projects/${projectId}/activities/${activityId}/timeentries/expert`,
    requestBody
  );
}

export function updateTimeEntry(
  timeEntryId,
  userId,
  organisationId,
  projectId,
  activityId,
  requestBody
) {
  return axios.put(
    `${API_PATH}/organisations/${organisationId}/persons/${userId}/projects/${projectId}/activities/${activityId}/timeentries/expert/${timeEntryId}`,
    requestBody
  );
}

export function getTimesheetReport(userId, organisationId, begin, end) {
  return axios.get(
    `${API_PATH}/organisations/${organisationId}/persons/${userId}/reports/timesheet/.xlsx?begin=${begin}&end=${end}`,
    { responseType: "blob", headers: { "Accept-Language": i18n.locale } }
  );
}

export function getMultiplePersonsTimesheetReport(
  organisationId,
  begin,
  end,
  personsIds
) {
  let baseUrl = `${API_PATH}/organisations/${organisationId}/persons/reports/timesheet/.xlsx?begin=${begin}&end=${end}`;
  let url = appendListParameters(baseUrl, "personId", personsIds);
  return axios.get(url, {
    responseType: "blob",
    headers: { "Accept-Language": i18n.locale }
  });
}

export function getProjectsForOrganisation(organisationId) {
  return axios.get(`${API_PATH}/organisations/${organisationId}/projects`);
}

export function getInvoiceForOrganisationAndProject(
  organisationId,
  projectIds,
  validFrom,
  validTo
) {
  const projectsParam = projectIds.map(id => `projects=${id}`).join("&");
  return axios.get(
    `${API_PATH}/organisations/${organisationId}/projects/${projectIds[0]}/invoice?validFrom=${validFrom}&validTo=${validTo}&${projectsParam}`
  );
}

export function generateInvoice(
  organisationId,
  projectId,
  validFrom,
  validTo,
  payload
) {
  return axios.post(
    `${API_PATH}/organisations/${organisationId}/projects/${projectId[0]}/invoice/.fodt?validFrom=${validFrom}&validTo=${validTo}`,
    payload,
    { responseType: "blob", headers: { "Accept-Language": i18n.locale } }
  );
}

export function getMultipleProjectsTimesheetReport(
  organisationId,
  begin,
  end,
  projectsIds
) {
  let baseUrl = `${API_PATH}/organisations/${organisationId}/projects/reports/timesheet/.xlsx?begin=${begin}&end=${end}`;
  let url = appendListParameters(baseUrl, "projectId", projectsIds);
  return axios.get(url, {
    responseType: "blob",
    headers: { "Accept-Language": i18n.locale }
  });
}

export function getFlexitimeReport(userId, organisationId) {
  return axios.get(
    `${API_PATH}/organisations/${organisationId}/persons/${userId}/reports/flexitime/.xlsx`,
    { responseType: "blob", headers: { "Accept-Language": i18n.locale } }
  );
}

export function getMultipleFlexitimeReport(organisationId, personsIds) {
  let baseUrl = `${API_PATH}/organisations/${organisationId}/persons/reports/flexitime/.xlsx`;
  let url = baseUrl + "?";
  url = appendListParameters(url, "personId", personsIds);
  return axios.get(url, {
    responseType: "blob",
    headers: { "Accept-Language": i18n.locale }
  });
}

function appendListParameters(baseUrl, paramName, params) {
  let url = baseUrl;
  params.forEach(param => (url = url + `&${paramName}=${param}`));
  return url;
}
