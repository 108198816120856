<template>
  <div>
    <div class="row">
      <div class="col">
        <h1 class="mt-5 mb-5 text-center">{{ $t("current.month") }}</h1>
      </div>
    </div>
    <b-row cols="1" cols-md="2" cols-lg="3">
      <b-col>
        <StatisticsCard v-bind:item="statistics.plannedTime" />
      </b-col>
      <b-col>
        <StatisticsCard v-bind:item="statistics.reportedTime" />
      </b-col>
      <b-col>
        <StatisticsCard
          v-bind:item="statistics.remainingTime"
          :type="remainingTimeType"
        />
      </b-col>
      <b-col>
        <StatisticsCard v-bind:item="statistics.monthlyGoal" type="SUCCESS" />
      </b-col>
      <b-col>
        <StatisticsCard
          v-bind:item="statistics.currentDailyProgress"
          type="SUCCESS"
        />
      </b-col>
      <b-col>
        <StatisticsCard
          v-bind:item="statistics.currentWeeklyProgress"
          type="SUCCESS"
        />
      </b-col>
    </b-row>
    <div class="row">
      <div class="col"><AdditionalReportsCard class="container" /></div>
    </div>
  </div>
</template>

<script>
import StatisticsCard from "./StatisticsCard";
import AdditionalReportsCard from "./AdditionalReportsCard";
import { getStatistics } from "@/service/temposervice";

export default {
  name: "Summary",
  components: {
    StatisticsCard,
    AdditionalReportsCard
  },
  props: { context: null },
  data() {
    return {
      statistics: {},
      remainingTimeType: "DEFAULT"
    };
  },
  methods: {
    loadStatistics: function() {
      if (this.context.userId) {
        getStatistics(this.context.userId, this.context.organisationId).then(
          response => {
            this.statistics = response.data.data;
            this.setRemainingTimeType();
          }
        );
      }
    },
    setRemainingTimeType: function() {
      if (this.statistics.remainingTime.value <= 0) {
        this.remainingTimeType = "SUCCESS";
      } else {
        this.remainingTimeType = "WARNING";
      }
    }
  },
  mounted() {
    this.loadStatistics();
  },
  watch: {
    context() {
      this.loadStatistics();
    }
  }
};
</script>
