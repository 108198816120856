<template>
  <div>
    <div class="row">
      <div class="col">
        <TempoAlert
          v-bind:variant="alert.variant"
          v-bind:message-key="alert.successMessageKey"
          v-bind:parent-dismiss-count-down="alert.dismissCountDown"
        />
      </div>
    </div>
    <ExpertForm
      v-bind:projectOptions="projects"
      actionMessageKey="add.time"
      @onSubmit="onSubmit"
    />
  </div>
</template>

<script>
import { createExpertTimeEntry } from "@/service/temposervice";
import TempoAlert from "@/components/timeentry/TempoAlert";
import ExpertForm from "@/components/timeentry/ExpertForm";

export default {
  name: "ExpertModeAddTimeEntry",
  components: { TempoAlert, ExpertForm },
  props: {
    userId: String,
    organisationId: String,
    projects: null
  },
  data() {
    return {
      alert: {
        variant: "success",
        successMessageKey: "timeEntry.added.success",
        dismissCountDown: 0
      }
    };
  },
  methods: {
    onSubmit(data, selectedProject) {
      this.addTimeEntry(data, selectedProject);
    },
    addTimeEntry: function(data, selectedProject) {
      return createExpertTimeEntry(
        this.userId,
        this.organisationId,
        selectedProject.split(".")[1],
        selectedProject.split(".")[0],
        data
      )
        .then(() => {
          this.triggerAlert();
        })
        .catch(() => {
          console.log("Error");
        })
        .finally(() => {
          this.resetAlertTrigger();
        });
    },
    triggerAlert: function() {
      this.alert.dismissCountDown = 3;
    },
    resetAlertTrigger: function() {
      this.alert.dismissCountDown = 0;
    }
  }
};
</script>

<style scoped lang="scss"></style>
