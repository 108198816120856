<template>
  <div id="menu-header">
    <div class="row mt-5 mb-5">
      <div class="col">
        <h1 class="display-1 text-center title">{{ $t("app.title") }}</h1>
        <h4 class="text-center font-weight-light">{{ $t("app.slogan") }}</h4>
      </div>
    </div>

    <b-navbar
      class="hidden-by-datepickers"
      :sticky="true"
      variant="light"
      toggleable="lg"
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item>
            <router-link :to="{ name: 'Dashboard' }">
              <a class="nav-link" href="#">{{ $t("dashboard") }}</a>
            </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link :to="{ name: 'TimeEntriesTable' }">
              <a class="nav-link" href="#">{{ $t("overview") }}</a>
            </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link :to="{ name: 'TimeEntry' }">
              <a class="nav-link">{{ $t("add") }}</a>
            </router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link :to="{ name: 'Export' }">
              <a class="nav-link">{{ $t("export") }}</a>
            </router-link>
          </b-nav-item>
          <b-nav-item v-if="isSuperUser">
            <router-link :to="{ name: 'Invoice' }">
              <a class="nav-link">{{ $t("invoice") }}</a>
            </router-link>
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <LanguageSwitcher class="nav-item-dropdown" />
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher";
export default {
  name: "MenuHeader",
  props: { context: null },
  components: { LanguageSwitcher },
  methods: {
    isSuperUser() {
      if (!this.context.userRoles) {
        return false;
      }

      let result =
        this.context.userRoles.includes("MANAGER") ||
        this.context.userRoles.includes("ADMIN");
      return result;
    }
  },
  mounted() {
    this.isSuperUser();
  }
};
</script>

<style scoped lang="scss">
.title {
  font-family: Caveat;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.nav-item-dropdown {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.nav-link {
  display: block;
  padding: 0.25rem 1rem;
}

.hidden-by-datepickers {
  z-index: 900;
}
</style>
