<template>
  <b-nav-item-dropdown :text="$t('language')">
    <b-dropdown-item
      :key="language"
      v-on:click="onLanguageChange(language)"
      v-for="language in getLanguages()"
    >
      {{ language }}
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import i18n from "../i18n/i18n";
import { LANGUAGES } from "@/config/languages";

export default {
  name: "LanguageSwitcher",
  methods: {
    getLanguages() {
      return LANGUAGES;
    },
    isActive(language) {
      return language === i18n.locale;
    },
    onLanguageChange(language) {
      if (!this.isActive(language)) {
        const url = new URL(window.location.href);
        url.searchParams.set("lang", language);
        window.location.assign(url.href);
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
