<template>
  <div class="card border-light shadow rounded text-center mb-2">
    <div v-if="item" class="card-body">
      <h1
        class="card-title mb-2 font-weight-bold"
        :style="{ color: headerColor }"
      >
        {{ item.value | formatValue }} {{ item.unit | formatUnit }}
      </h1>
      <p class="mb-0 mt-2">{{ $t(item.key + ".label") }}</p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import i18n from "../../i18n/i18n";

Vue.filter("formatValue", function(value) {
  if (value) {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }
  return value;
});

Vue.filter("formatUnit", function(value) {
  if (value) {
    if (value === "HOUR") {
      return i18n.t("hours.short");
    }
    if (value === "PERCENT") {
      return "%";
    }
  }
});

export default {
  name: "Card",
  props: { item: Object, type: String },
  data() {
    return {
      headerColor: this.setColor()
    };
  },
  methods: {
    setColor() {
      if (this.type === "WARNING") {
        return "orange";
      } else if (this.type === "SUCCESS") {
        return "mediumseagreen";
      } else {
        return "black";
      }
    }
  },
  watch: {
    type: function() {
      this.headerColor = this.setColor();
    }
  }
};
</script>
