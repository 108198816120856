<template>
  <div class="card border-light shadow rounded text-center mb-2">
    <div class="card-body">
      <h3 class="card-title mb-2 font-weight-bold">
        {{ $t("export.flexitime") }}
      </h3>
      <b-button v-on:click="getFlexitime" variant="light">{{
        $t("export.flexitime")
      }}</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlexitimeExportCard",
  methods: {
    getFlexitime: function() {
      this.$emit("getFlexitime");
    }
  }
};
</script>
